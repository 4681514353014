



































































import mixins from 'vue-typed-mixins'
import WordCreateEditFields from '@/components/Word/CreateEditFields.vue'
import {
  vModel,
  form,
} from '@/mixins'
import {
  wordStore,
  snackbarStore,
} from '@/store'
import {
  newWord,
} from '@/util/word'
import {
  Word,
} from '@/types/word'
import {
  Id,
} from '@/types/base'
import omit from 'lodash.omit'

export default mixins(form, vModel)
  .extend({
    props: {
      wordId: {
        default: ``,
        type: String,
      },
    },
    components: {
      WordCreateEditFields,
    },
    data: () => ({
      editWord: newWord(),
      loadingSave: false,
      loadingDelete: false,
    }),
    computed: {
      word (): (Word & Id) | undefined {
        return wordStore.dataById(this.wordId)
      },
      hasChanged (): boolean {
        if (!this.word) return false
        const oldWord = this.$copy(this.word)
        const editWord = omit(oldWord, [
          `id`,
        ])
        return !this.$isEqual<Word>(this.editWord, editWord)
      },
    },
    watch: {
      word: {
        immediate: true,
        deep: true,
        handler (word: (Word & Id)) {
          this.reset(word)
        },
      },
    },
    methods: {
      async save () {
        if (!this.validateForm()) return
        const editWord: (Word & Id) = {
          id: this.wordId,
          ...this.$copy(this.editWord),
        }
        this.loadingSave = true
        try {
          await wordStore.update(editWord)
          this.value_ = false
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error creating word`,
          })
        }
        this.loadingSave = false
      },
      async deleteWord () {
        this.loadingDelete = true
        try {
          await wordStore.delete(this.wordId)
          this.value_ = false
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error deleting word`,
          })
        }
        this.loadingDelete = false
      },
      async reset (word: (Word & Id) | undefined) {
        const oldWord = word !== undefined
          ? this.$copy(word)
          : {
            ...newWord(), id: ``,
          }
        const editWord = omit(oldWord, [
          `id`,
        ])
        this.editWord = editWord
      },
    },
  })
