






































































import Vue from 'vue'
import CreateWordDialog from '@/components/Word/CreateDialog.vue'
import EditWordDialog from '@/components/Word/EditDialog.vue'
import {
  wordStore,
  snackbarStore,
} from '@/store'
import {
  Word,
} from '@/types/word'
import {
  Id,
} from '@/types/base'
import {
  DataTableHeader,
} from 'vuetify'

export default Vue.extend({
  components: {
    CreateWordDialog,
    EditWordDialog,
  },
  data: () => ({
    createWordDialog: false,
    editWordDialog: false,
    editWordId: ``,
    headers: [
      {
        text: `ID`,
        value: `id`,
      },
      {
        text: `Text`,
        value: `text`,
      },
      {
        text: `Translations`,
        value: `translations`,
      },
      {
        text: ``,
        value: ``,
        sortable: false,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    words: () => wordStore.data,
  },
  async created () {
    try {
      await wordStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load words`,
      })
    }
  },
  methods: {
    async deleteWord (word: (Word & Id)) {
      if (!confirm(`Are you sure you want to delete "${word.text}"?`)) return
      try {
        await wordStore.delete(word.id)
      } catch (err) {
        snackbarStore.addSiteError({
          err, snackMessage: `Error deleting word`,
        })
      }
    },
    editWord (id: string) {
      this.editWordId = id
      this.editWordDialog = true
    },
  },
})
