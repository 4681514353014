





















































import mixins from 'vue-typed-mixins'
import WordCreateEditFields from '@/components/Word/CreateEditFields.vue'
import {
  vModel,
  form,
} from '@/mixins'
import {
  wordStore,
  snackbarStore,
} from '@/store'
import {
  newWord,
} from '@/util/word'

export default mixins(form, vModel)
  .extend({
    components: {
      WordCreateEditFields,
    },
    data: () => ({
      newWord: newWord(),
      loadingCreate: false,
    }),
    watch: {
      value_ (value: boolean) {
        if (!value) return
        this.newWord = newWord()
      },
    },
    methods: {
      async create () {
        if (!this.validateForm()) return
        this.loadingCreate = true
        try {
          await wordStore.create(this.newWord)
          this.value_ = false
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error creating word`,
          })
        }
        this.loadingCreate = false
      },
    },
  })
